@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Nunito", sans-serif;
}

::-webkit-scrollbar {
  width: 0.2rem;
  border-radius: 0.6rem;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.6rem;
  background-color: #21222c;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #363536;
}
